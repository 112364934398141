import React from "react";
import { graphql } from "gatsby";
import Image from "../components/Image";

import Layout from "../components/Layout";
import PortableText from "../components/PortableText";

export const query = graphql`
  query ProjectQuery($id: String!) {
    sanityProject(id: { eq: $id }) {
      title
      _rawDescription
      coverImage {
        asset {
          id
          originalFilename
          url
        }
        altText
      }
    }
  }
`;

export default function Project({ data: { sanityProject } }) {
  return (
    <Layout title="Project" page="project" url="/project">
      {sanityProject.title && (
        <h1 className="font-h1">{sanityProject.title}</h1>
      )}
      {sanityProject.coverImage ? (
        <Image image={sanityProject.coverImage} className="cover-image" />
      ) : (
        <div className="cover-image empty"></div>
      )}
      {sanityProject._rawDescription && (
        <PortableText blocks={sanityProject._rawDescription} />
      )}
    </Layout>
  );
}
